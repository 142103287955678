import { usePathname } from 'next/navigation';

import { AI_AGENT_RELATED_ENTITY_TYPE, AiAgentRelatedEntityType } from '@api/types/aiAgent';

const ENTITY_TYPE_MAP = {
  app: AI_AGENT_RELATED_ENTITY_TYPE.APPLICATION,
  'hot-offers': AI_AGENT_RELATED_ENTITY_TYPE.HOT_OFFER,
  'reward-pools': AI_AGENT_RELATED_ENTITY_TYPE.REWARD_POOL,
  sale: AI_AGENT_RELATED_ENTITY_TYPE.SALE_POOL,
  staking: AI_AGENT_RELATED_ENTITY_TYPE.STAKING,
};

const getEntityType = (entityTypeFromPath: string): AiAgentRelatedEntityType | null =>
  ENTITY_TYPE_MAP[entityTypeFromPath as keyof typeof ENTITY_TYPE_MAP] || null;

const getEntityIdFromPath = (path: string, category: string): string | null => {
  const regex = new RegExp(`/${category}/([^/]+)`);
  const match = path.match(regex);
  return match ? match[1] : null;
};

export const useRelatedEntity = () => {
  const path = usePathname();

  if (!path) {
    return { relatedEntity: null };
  }

  for (const category of Object.keys(AI_AGENT_RELATED_ENTITY_TYPE)) {
    if (path.includes(category)) {
      const entityType = getEntityType(category);
      const entityId = getEntityIdFromPath(path, category);

      if (!entityId && entityType !== AI_AGENT_RELATED_ENTITY_TYPE.STAKING) {
        return { relatedEntity: null };
      }

      return {
        relatedEntity: {
          ...(entityId && { id: entityId }),
          type: entityType,
        },
      };
    }
  }

  return { relatedEntity: null };
};
