import { useAiAssistantQuotasQuery } from '@query';

import { useNavLayout } from '@shared/common/components/NavLayout';
import { nextProjectUrl } from '@shared/constants/urls';

import { ExternalLink } from '@ui/uikit/components/common/ExternalLink';
import { Typography } from '@ui/uikit/components/common/Typography';
import { formatAmountCompact } from '@ui/uikit/utils';

import { IconForBlock } from './IconForBlock';

export const GetCreditsTab = () => {
  const { user } = useNavLayout();

  const { data: quotas = [] } = useAiAssistantQuotasQuery({ enabled: !!user });

  return (
    <div className="w-full">
      <div className="py-6">
        <p className="text-caption-sm-strong-a">
          Stake SQR to earn SQRv points and unlock more monthly Mystic AI Credits
        </p>
        <p className="text-caption-xs-a">
          The more SQR you stake, the more Mystic AI monthly credits you’ll receive.
        </p>
      </div>
      <div>
        <p className="text-caption-sm-strong-a pb-4">SQRv Points Levels</p>
        <div className="grid grid-cols-4 gap-2 pb-4 max-md-msq:grid-cols-2">
          {quotas?.map((block, index) => (
            <div
              key={index}
              className="border border-base-border-light p-4 rounded-xl flex flex-col gap-2 w-full"
            >
              <IconForBlock index={index} />
              <div className="flex flex-col gap-0">
                <Typography noWrap variant="caption-s-strong">
                  {formatAmountCompact(block.monthCredits)} Credits
                </Typography>
                <Typography noWrap variant="caption-xs" color="tertiary">
                  {index === 0 ? '1-time Bonus' : 'Monthly'}
                </Typography>
              </div>
              <Typography variant="caption-xs-strong" color="primary">
                {block.minSQRv === 0 ? (
                  <span>
                    Free
                    <br />
                    Users
                  </span>
                ) : (
                  <span>
                    {`${formatAmountCompact(block.minSQRv)} SQRv`}
                    <br />
                    Holders
                  </span>
                )}
              </Typography>
            </div>
          ))}
        </div>
        <ExternalLink
          href={nextProjectUrl.staking('/')}
          className="msq-btn msq-btn-primary msq-btn-lg"
        >
          Stake SQR
        </ExternalLink>
      </div>
    </div>
  );
};
