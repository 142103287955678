import { useState } from 'react';

import * as Dialog from '@radix-ui/react-dialog';

import { ChatContent } from './ChatContent';
import { Menu } from './screens/Menu/Menu';

export type AiAgentScreens = 'chat' | 'balance';

export const ChatDialog = ({ ...props }: Dialog.DialogProps) => {
  const [activeScreen, setActiveScreen] = useState<AiAgentScreens>('chat');

  return (
    <Dialog.Root {...props}>
      <Dialog.Portal>
        <Dialog.Overlay
          forceMount
          className="msq-dialog msq-dialog-animate msq-dialog-mobile-menu-m xl-msq:msq-dialog-mobile-menu-d bg-transparent top-[var(--nav-layout-header-height)]"
        >
          <Dialog.Content
            forceMount
            aria-describedby={undefined}
            className="msq-dialog-content sm-msq:msq-dialog-content-right
             border border-base-border-light block h-full overflow-hidden
             w-[580px] max-md-msq:w-full z-20"
          >
            <div className="grid grid-cols-[1fr_50px] h-full">
              <div className="border-r w-full border-base-border-light h-full overflow-hidden">
                <ChatContent
                  activeScreen={activeScreen}
                  backToChat={() => setActiveScreen('chat')}
                />
              </div>
              <Menu onGoToScreen={setActiveScreen} />
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
