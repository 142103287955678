import { useState } from 'react';

import { useAiAssistantStatusQuery, useUserSqrvBalance } from '@query';

import { useNavLayout } from '@shared/common/components/NavLayout';
import { formatNumberLocalized } from '@shared/helpers/formatters';

import { InfoTooltip } from '@ui/uikit/components/common/Tooltip';
import { Typography } from '@ui/uikit/components/common/Typography';
import { ArrowLeft } from '@ui/uikit/components/icons/mono';

import { BalanceTabName, BalanceTabs } from './BalanceTabs/BalanceTabs';

type CreditsProps = {
  backToChat: () => void;
};

export const BalanceScreen = ({ backToChat }: CreditsProps) => {
  const [activeTab, setActiveTab] = useState<BalanceTabName>(BalanceTabName.CREDITS);

  const { user } = useNavLayout();

  const { data } = useAiAssistantStatusQuery({ enabled: !!user });
  const { data: sqrvBalance } = useUserSqrvBalance();

  return (
    <div className="w-full flex flex-col h-full">
      <div className="p-3 border-b border-base-border-light">
        <div className="flex items-center cursor-pointer gap-4" onClick={backToChat}>
          <ArrowLeft className="icon-xs" />
          <Typography variant="caption-s">Back to Chat</Typography>
        </div>
      </div>
      <div className="flex flex-col items-center w-full max-h-full p-6 overflow-y-auto py-6">
        <div className="flex justify-between w-full gap-2 pb-8">
          <div className="flex flex-col border border-base-border-light w-full rounded-xl p-4 gap-2">
            <Typography color="tertiary" variant="caption-xs">
              <div className="flex items-center">
                Credits <InfoTooltip message="Your remaining credits balance." />
              </div>
            </Typography>
            <Typography variant="h3">
              {formatNumberLocalized(
                (data?.usage.creditsLimit || 0) - (data?.usage.creditsUsed || 0) || 0,
              )}
            </Typography>
          </div>
          <div className="flex flex-col border border-base-border-light w-full rounded-xl p-4 gap-2">
            <Typography color="tertiary" variant="caption-xs">
              <div className="flex items-center">
                SQRv{' '}
                <InfoTooltip
                  message={
                    <>
                      This is your Total Voting Power (TVP), expressed in <b>SQRv points</b>,
                      calculated as <b>TVP = BVP + TSVP</b>. <b>BVP</b> is the number of SQR tokens
                      you hold, and <b>TSVP</b> depends on how many SQR tokens you stake and the
                      duration of your stake (Not real-time, 1-hour delay).
                    </>
                  }
                />
              </div>
            </Typography>
            <Typography variant="h3">{formatNumberLocalized(sqrvBalance?.score || 0)}</Typography>
          </div>
        </div>
        <BalanceTabs activeTab={activeTab} onClickTab={setActiveTab} />
      </div>
    </div>
  );
};
