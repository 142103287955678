import { MagicAi } from '@ui/uikit/components/icons/poly/MagicAi';

import { DefaultCategories } from './DefaultCategories';

type Props = {
  onCardClick: (message: string) => void;
};

export const WelcomeBlock = ({ onCardClick }: Props) => {
  return (
    <div className="w-full h-full min-h-fit self-center p-6">
      <div className="h-full flex flex-col justify-center items-center text-center text-base-text-primary text-sm">
        <div className="flex items-center justify-center size-[3rem] msq-btn-brand-gradient rounded-full mb-4">
          <MagicAi className="icon-[1.5rem]" />
        </div>
        <div className="mb-4">
          <p className="mb-6">
            I’m Mystic, here to assist
            <br />
            and guide you through your journey.
          </p>
          <p className="font-semibold mb-2">Ready to get started? </p>
          <p>
            Choose one of the options below to begin,
            <br />
            and let’s create magic together!
          </p>
        </div>
        <DefaultCategories onCardClick={onCardClick} />
      </div>
    </div>
  );
};
