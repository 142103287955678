export const AI_AGENT_RELATED_ENTITY_TYPE = {
  APPLICATION: 'APPLICATION',
  HOT_OFFER: 'HOT_OFFER',
  REWARD_POOL: 'REWARD_POOL',
  SALE_POOL: 'SALE_POOL',
  STAKING: 'STAKING',
};

export type AiAgentRelatedEntityTypeName = keyof typeof AI_AGENT_RELATED_ENTITY_TYPE;

export type AiAgentRelatedEntityType =
  (typeof AI_AGENT_RELATED_ENTITY_TYPE)[AiAgentRelatedEntityTypeName];
