import { ComponentType, SVGProps } from 'react';

import classNames from 'classnames';

import { BarChart, MagicSquareLogo, Speaker } from '@ui/uikit/components/icons/mono';

type WelcomeScreenCategory = {
  Icon: ComponentType<SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
  className: {
    bg: string;
    icon: string;
  };
};

const categoriesContent: WelcomeScreenCategory[] = [
  {
    title: 'Mystic & Magic Square Info',
    description: 'Explore Mystic & Magic Square Info!',
    Icon: MagicSquareLogo,
    className: {
      bg: 'bg-base-bg-secondary-pink',
      icon: 'text-base-text-pink',
    },
  },
  {
    title: 'Crypto News',
    description: 'Stay Up-to-Date with Crypto News!',
    Icon: Speaker,
    className: {
      bg: 'bg-base-bg-secondary-blue',
      icon: 'text-base-text-blue',
    },
  },
  {
    title: 'Real-Time Market Intelligence',
    description: 'Unlock Real-Time Market Intelligence!',
    Icon: BarChart,
    className: {
      bg: 'bg-base-bg-secondary-green',
      icon: 'text-base-text-green',
    },
  },
];

type Props = {
  onCardClick: (message: string) => void;
};

export const DefaultCategories = ({ onCardClick }: Props) => {
  return (
    <div className="flex flex-col gap-2">
      {categoriesContent.map(({ Icon, title, description, className }, index) => {
        return (
          <div
            key={index}
            className="flex flex-row gap-2 border border-primary-100 rounded-lg p-3 text-left cursor-pointer"
            onClick={() => onCardClick(title)}
          >
            <span
              className={classNames(
                'msq-btn-icon-lg rounded-lg flex justify-center items-center',
                className.bg,
              )}
            >
              <Icon className={classNames('icon-sm', className.icon)} />
            </span>
            <div>
              <p className="font-semibold">{title}</p>
              <p className="text-[13px] text-base-text-tertiary">{description}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
